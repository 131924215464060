exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-cahle-js": () => import("./../../../src/pages/acerca-de-cahle.js" /* webpackChunkName: "component---src-pages-acerca-de-cahle-js" */),
  "component---src-pages-acuerdos-y-reglamentos-js": () => import("./../../../src/pages/acuerdos-y-reglamentos.js" /* webpackChunkName: "component---src-pages-acuerdos-y-reglamentos-js" */),
  "component---src-pages-beneficios-js": () => import("./../../../src/pages/beneficios.js" /* webpackChunkName: "component---src-pages-beneficios-js" */),
  "component---src-pages-congreso-ceiba-js": () => import("./../../../src/pages/congreso-ceiba.js" /* webpackChunkName: "component---src-pages-congreso-ceiba-js" */),
  "component---src-pages-congreso-choluteca-js": () => import("./../../../src/pages/congreso-choluteca.js" /* webpackChunkName: "component---src-pages-congreso-choluteca-js" */),
  "component---src-pages-congreso-olanchito-ix-js": () => import("./../../../src/pages/congreso-olanchito-ix.js" /* webpackChunkName: "component---src-pages-congreso-olanchito-ix-js" */),
  "component---src-pages-congreso-olanchito-js": () => import("./../../../src/pages/congreso-olanchito.js" /* webpackChunkName: "component---src-pages-congreso-olanchito-js" */),
  "component---src-pages-congreso-tegucigalpa-js": () => import("./../../../src/pages/congreso-tegucigalpa.js" /* webpackChunkName: "component---src-pages-congreso-tegucigalpa-js" */),
  "component---src-pages-congresos-anteriores-js": () => import("./../../../src/pages/congresos-anteriores.js" /* webpackChunkName: "component---src-pages-congresos-anteriores-js" */),
  "component---src-pages-crel-js": () => import("./../../../src/pages/crel.js" /* webpackChunkName: "component---src-pages-crel-js" */),
  "component---src-pages-documentos-varios-js": () => import("./../../../src/pages/documentos-varios.js" /* webpackChunkName: "component---src-pages-documentos-varios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-general-congreso-2023-js": () => import("./../../../src/pages/informacion-general-congreso-2023.js" /* webpackChunkName: "component---src-pages-informacion-general-congreso-2023-js" */),
  "component---src-pages-informes-anuales-js": () => import("./../../../src/pages/informes-anuales.js" /* webpackChunkName: "component---src-pages-informes-anuales-js" */),
  "component---src-pages-inscripciones-congreso-2024-js": () => import("./../../../src/pages/inscripciones-congreso-2024.js" /* webpackChunkName: "component---src-pages-inscripciones-congreso-2024-js" */),
  "component---src-pages-junta-directiva-js": () => import("./../../../src/pages/junta-directiva.js" /* webpackChunkName: "component---src-pages-junta-directiva-js" */),
  "component---src-pages-leyes-y-reformas-js": () => import("./../../../src/pages/leyes-y-reformas.js" /* webpackChunkName: "component---src-pages-leyes-y-reformas-js" */),
  "component---src-pages-memorias-congresos-js": () => import("./../../../src/pages/memorias-congresos.js" /* webpackChunkName: "component---src-pages-memorias-congresos-js" */),
  "component---src-pages-mensaje-presidente-js": () => import("./../../../src/pages/mensaje-presidente.js" /* webpackChunkName: "component---src-pages-mensaje-presidente-js" */),
  "component---src-pages-normas-internacionales-y-nacionales-js": () => import("./../../../src/pages/normas-internacionales-y-nacionales.js" /* webpackChunkName: "component---src-pages-normas-internacionales-y-nacionales-js" */),
  "component---src-pages-noticias-y-eventos-js": () => import("./../../../src/pages/noticias-y-eventos.js" /* webpackChunkName: "component---src-pages-noticias-y-eventos-js" */),
  "component---src-pages-personal-administrativo-js": () => import("./../../../src/pages/personal-administrativo.js" /* webpackChunkName: "component---src-pages-personal-administrativo-js" */),
  "component---src-pages-proyectos-ejecutados-js": () => import("./../../../src/pages/proyectos-ejecutados.js" /* webpackChunkName: "component---src-pages-proyectos-ejecutados-js" */),
  "component---src-pages-reglamentacion-tecnica-centroamericana-js": () => import("./../../../src/pages/reglamentacion-tecnica-centroamericana.js" /* webpackChunkName: "component---src-pages-reglamentacion-tecnica-centroamericana-js" */),
  "component---src-pages-reglamentaciones-js": () => import("./../../../src/pages/reglamentaciones.js" /* webpackChunkName: "component---src-pages-reglamentaciones-js" */),
  "component---src-pages-representaciones-js": () => import("./../../../src/pages/representaciones.js" /* webpackChunkName: "component---src-pages-representaciones-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-varios-js": () => import("./../../../src/pages/varios.js" /* webpackChunkName: "component---src-pages-varios-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

